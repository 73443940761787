import { Button, Text } from "@gravity-ui/uikit"
import { DeviceProps } from "pages/Main"
import { MeQuery } from "queries"
import { createElement as $, FC, cloneElement } from "react"
import './style.scss'

const MobileNavigation: FC<MobileNavigationProps> = ({
  navItems
}) =>
  $('div', { className: 'mobile-navigation' },
    $('div', { className: 'mobile-navigation_nav-items' },
      navItems.map(NavItemIteratee)))

const NavItemIteratee = (props: MobileNavigationProps['navItems'][number]) => {
  const iconProps = { ...props.icon.props, size: '1.25rem' }
  const icon = cloneElement(props.icon, { ...iconProps })

  const ButtonContent = () => 
    $('div', { className: 'mobile-navigation_nav-items_button' }, 
      icon,
      $(Text, { 
        variant: 'caption-1',
        className: 'mobile-navigation_nav-items_button_label' 
        }, 
        props.label.toUpperCase()))

  return props.visible !== false 
    && $(Button, {
      className: 'mobile-navigation_nav-items_button',
      size: 'm',
      key: props.label,
      width: 'max',
      selected: props.selected,
      onClick: props.onClick,
      disabled: props.disabled,
      }, $(ButtonContent))
}

type MobileNavigationProps = Pick<DeviceProps, 'navItems'> & {
  data?: MeQuery | undefined
}

export default MobileNavigation