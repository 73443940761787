import { useLayoutContext } from '@gravity-ui/uikit'
import ZiferblatContext from 'components/ZiferblatContext'
import { addYears } from 'date-fns/fp/addYears'
import { useCalendarEventsQuery } from 'queries'
import { createElement as $, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import DesktopList from './CalendarEventsList/Desktop'
import MobileList from './CalendarEventsList/Mobile'
import CulturalEvent from './CulturalEvent'
import Booking from './Booking'

const CalendarEvents = () =>
	$(Routes, null,
		$(Route, {
			path: ':id',
			element: $(CulturalEvent)
		}),
		$(Route, { 
			path: '/*',
			element: $(CalendarEventsList)
		}),
    $(Route, {
			path: '/booking/:id',
			element: $(Booking)
		})
  )

const before = addYears(10, new Date())

const CalendarEventsList = () => {
	const { activeMediaQuery } = useLayoutContext()
	const { ziferblatId } = useContext(ZiferblatContext)
	const component = activeMediaQuery === 's' ? MobileList : DesktopList
  const calendarEvents = useCalendarEventsQuery({
    fetchPolicy: 'cache-and-network',
		variables: {
			ziferblatId,
			before
		}
  })

	return $(component, calendarEvents)
}

export type CalendarEventProps = ReturnType<typeof useCalendarEventsQuery>

export default CalendarEvents
