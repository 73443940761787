import { FormRow } from '@gravity-ui/components'
import { DatePicker } from '@gravity-ui/date-components'
import { DateTime, dateTime, dateTimeParse } from '@gravity-ui/date-utils'
import { Card, Flex, spacing, TextArea, useLayoutContext } from '@gravity-ui/uikit'
import PhoneInput from 'components/PhoneInput'
import { useFormikContext } from 'formik'
import { BookingFragment } from 'queries'
import { createElement as $, FC, Fragment, PropsWithChildren, RefObject, useRef } from 'react'
import { IMaskInput } from 'react-imask'
import { useIntl } from 'react-intl'
import EventControls from '../EventControls'
import FormControls, { DeleteButton } from './FormControls'

const BookingForm = () => {
  const { values, handleChange, handleBlur, errors } = useFormikContext<Form>()
  const { activeMediaQuery } = useLayoutContext()
  const isMobile = activeMediaQuery === 's'
  const direction = isMobile ? 'column' : 'row'
  const intl = useIntl()
  const onBlur = (name: string) => () => handleBlur({ target: { name }})
  const onChange = (name: string) => <T>(input: T) => handleChange({ target: { value: input, name }})
  const startTimeInputRef = useRef<HTMLInputElement>(null)
  
  const handleInputFocus = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current && isMobile) 
      try { ref.current.showPicker() }
    catch (error) { console.log(error) }
  }

  return $(FormWrapper, { isMobile }, 
      $(FormRow, { 
        direction,
        label: intl.formatMessage({ id: 'booking.name' }),
        },
        $(TextArea, {
          value: values.name || '',
          onUpdate: onChange('name'),
          onBlur: onBlur('name'),
          autoFocus: isMobile && !values.name,
          validationState: errors.name ? 'invalid' : undefined,
          errorMessage: intl.formatMessage({ id: 'errors.required' })
        })),
      $(FormRow, { 
        direction,
        label: intl.formatMessage({ id: 'booking.phone' })
        },
        $(PhoneInput, {
          value: values.phone || '',
          onBlur: onBlur('phone'),
          onUpdate: onChange('phone'),
          style: { flexGrow: 1 }
        }),
        $(IMaskInput, { 
          style: { display: 'none' },  
          value: values.phone || '', 
          onAccept: onChange('phone')}
        )),
      $(FormRow, {
        direction,
        label: intl.formatMessage({ id: 'booking.startsAt' })
        },
        $(Flex, null,
          $('div', { style: { position: 'relative', width: '100%' }},
            $(DatePicker, { 
              format: 'HH:mm', 
              pin: 'round-clear', 
              value: dateTime({ input: values?.startsAt }),
              onFocus: () => handleInputFocus(startTimeInputRef),
              onUpdate: (value) => {
                onChange('startsAt')(value)
              },
              onBlur: onBlur('startsAt'),
            }),
            $('input', { 
              ref: startTimeInputRef, 
              type: 'time', 
              value: values.startsAt?.format('HH:mm'),
              onChange(event) {
                const newStartTime = dateTimeParse(values.startsAt)?.format(`YYYY-MM-DDT${event.target.value}Z`)
                onChange('startsAt')(dateTime({ input: newStartTime }))
              },
              style: { 
                position: 'absolute',
                pointerEvents: 'none',
                left: 0,
                bottom: 0,
                opacity: 0, 
              }})),
          $(DatePicker, { 
            format: 'DD.MM.YYYY', 
            pin: 'clear-round', 
            value: dateTime({ input: values?.startsAt }),
            onUpdate: (value) => {
              onChange('startsAt')(value)
            },
            onBlur: onBlur('startsAt'),
          }))), 
      $(FormRow, { 
        direction,
        label: intl.formatMessage({ id: 'booking.comment' }),
        },
        $(TextArea, {
          value: values.comment || '',
          minRows: 3,
          onUpdate: onChange('comment'),
          onBlur: onBlur('comment'),
        })))
}

const FormWrapper: FC<PropsWithChildren & { isMobile: boolean }> = ({ children, isMobile }) => {
  if (!isMobile) 
    return $(Card, { 
      view: 'filled', 
      style: { maxWidth: '500px' }, 
      children: $(Flex, { 
        direction: 'column', 
        justifyContent: 'space-between',
        className: spacing({ p: 4 }), 
        }, 
        $(Fragment, null, 
          children,
          $(FormControls))) 
    })
  
  return $(Flex, {
    direction: 'column', 
    justifyContent: 'space-between',
    className: spacing({ p: 2 }), 
    }, 
    $(Fragment, null, 
      children,
      $(DeleteButton),
      $(EventControls)
    ))
}

export type Form = {
  name: string | null
  startsAt: DateTime | null
  comment: string | null
  phone: string | null
  checkins: BookingFragment['bookingCheckins'] | []
  hasCheckins: boolean
}

export default BookingForm