import { CircleInfo, FolderHouse, PersonWorker, SquareListUl } from '@gravity-ui/icons'
import Calendar from '@gravity-ui/icons/Calendar'
import Persons from '@gravity-ui/icons/Persons'
import TagRubleIcon from '@gravity-ui/icons/TagRuble'
import { Icon } from '@gravity-ui/uikit'
import CalendarEvents from 'pages/CalendarEvents'
import Checkins from 'pages/Checkins'
import Profile from 'pages/Profile'
import Rent from 'pages/Rent'
import Settings from 'pages/Settings'
import Shifts from 'pages/Shifts'
import Tariff from 'pages/Tariff'
import { createElement as $, ReactElement } from 'react'

export const paths: Record<string, PathConfig> = {
  checkins: {
    icon: $(Icon, { data: Persons }),
    element: $(Checkins),
    label: 'main.checkins',
    disabled: false,
  },
  calendar: {
    icon: $(Icon, { data: Calendar }),
    element: $(CalendarEvents),
    label: 'main.calendar',
    disabled: false,
  },
  tariff: {
    icon: $(Icon, { data: TagRubleIcon }),
    element: $(Tariff),
    label: 'main.tariff',
    disabled: false,
  },
  rent: {
    icon: $(Icon, { data: FolderHouse }),
    element: $(Rent),
    label: 'main.rent',
    disabled: true,
    visible: false
  },
  shifts: {
    icon: $(Icon, { data: SquareListUl }),
    element: $(Shifts),
    label: 'main.shifts',
    disabled: true,
    visible: false
  },
  profile: {
    icon: $(Icon, { data: CircleInfo }),
    element: $(Profile),
    label: 'main.profile',
    disabled: false,
    visible: false
  },
  staff: {
    icon: $(Icon, { data: PersonWorker }),
    element: $(Settings),
    label: 'main.staff',
    disabled: false,
    visible: true
  }
}

export const pathEntries = Object.entries(paths)

export type PathConfig = {
  icon: ReactElement
  element: ReactElement
  label: FormatjsIntl.Message['ids']
  disabled: boolean
  visible?: boolean
}

export type PathConfigEntry = typeof pathEntries[number]